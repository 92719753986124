import * as React from "react";
import { css } from "@emotion/core";

import ArticleComponent from "../components/ArticleComponent";
import TextWrapper from "../components/TextWrapper";

const formStyle = theme => css`
  input {
    display: block;
    width: 500px;
    border: 1px solid #000;
    padding: 4px;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    max-width: 100%;
  }
  label {
    font-size: 0.9em;
  }
  button {
    font-family: inherit;
    color: inherit;
    appearance: none;
    background-color: #fff;
    border: 2px solid ${theme.colors.black};
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 5px 20px;
    cursor: pointer;
    display: inline-block;
  }
`;

const legalContainer = css`
  font-size: 0.75em;
`;

const buttonContainerStyle = css`
    display: flex;
    justify-content: center;
`

const Formularz = () => {
  return (
    <ArticleComponent title="Formularz zgłoszeniowy">
      <TextWrapper>
        <article>
          <h1>Formularz zgłoszeniowy</h1>
          <p>
            Nasza mapa będzie uzupełniana na bieżąco na podstawie Waszych
            zgłoszeń. Jeśli prowadzisz sklep, bar, kawiarnię, prywatna aptekę
            i/lub punkt usługowy lub rzemieślniczy, możesz zgłosić się do nas w
            celu umieszczenia go na mapie. Aby to zrobić wypełnij poniższy
            formularz.
          </p>
          <form name="zgloszenie-miejsca" css={formStyle} data-netlify="true" method="post">
          <input type="hidden" name="form-name" value="zgloszenie-miejsca" />
            <div>
              <label htmlFor="name">
                Imię i nazwisko zgłaszającego/ej (nie będą widoczne na mapie)
              </label>
              <input type="text" name="name" id="name" required />
            </div>
            <div>
              <label htmlFor="email">
                Adres e-mail do kontaktu (nie będzie widoczny na mapie)
              </label>
              <input type="email" name="email" id="email" required />
            </div>
            <div>
              <label htmlFor="phone">
                Numer telefonu do kontaktu (będzie widoczny na mapie)
              </label>
              <input type="phone" name="phone" id="phone" required />
            </div>
            <div>
              <label htmlFor="address">
                Adres sklepu / punktu usługowego (będzie widoczny na mapie)
              </label>
              <input type="text" name="address" id="address" required />
            </div>
            <div>
              <label htmlFor="phone_company">
                Number telefonu do sklepu / punktu usługowego (będzie widoczny
                na mapie)
              </label>
              <input type="phone" name="phone_company" id="phone_company" />
            </div>
            <div>
              <label htmlFor="www">
                Adres strony internetowej lub profilu w mediach
                społecznościowych (będą widoczne na mapie)
              </label>
              <input type="text" name="www" id="www" />
            </div>
            <div>
              <label htmlFor="source">
                Skąd wiesz o możliwości zgłoszenia się do mapy?
              </label>
              <input type="text" name="source" id="source" required />
            </div>
            <div>
              <label htmlFor="email_newsletter">
                Czy chesz otrzymywać newsletter fundacji Ładne Historie
                dotyczący prowadzonych przez nią działań edukacyjnych,
                kulturalnych, animacyjnych, szkoleniowych, pomocowych i
                związanych z lokalnym parterstwem dla osiedla Plac Grunwaldzki?
                Jeśli tak wpisz adres mail, na który mamy wysyłać ci newsletter.
              </label>
              <input
                type="email"
                name="email_newsletter"
                id="email_newsletter"
              />
            </div>
            <div css={legalContainer}>
              <p>
                Wysłanie niniejszego zgłoszenia oznacza wyrazenie zgody na
                przetwarzanie danych osobowych zgłaszających przez fundację
                Ładne Historie:
              </p>

              <p>
                Na podstawie ROZPORZĄDZENIA PARLAMENTU EUROPEJSKIEGO I RADY (UE)
                2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                fizycznych w związku z przetwarzaniem danych osobowych i w
                sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
                (Dz. Urz. UE L 119/1) – dalej RODO, Fundacja uprzejmie informuje
                Zgłaszających, że:{" "}
              </p>
              <ul>
                <li>
                  Administratorem przetwarzanych danych osobowych jest Fundacja
                  Ładne Historie, podmiot z siedzibą przy ulicy Długiej 33,
                  58-100 Świdnica.{" "}
                </li>

                <li>
                  Zgłaszającym przysługuje prawo dostępu do treści danych oraz
                  ich sprostowania, usunięcia lub ograniczenia przetwarzania, a
                  także prawo sprzeciwu, zażądania zaprzestania przetwarzania i
                  przenoszenia danych, jak również prawo do cofnięcia zgody w
                  dowolnym momencie oraz prawo do wniesienia skargi do organu
                  nadzoru – Prezesa Urzędu Ochrony Danych Osobowych.{" "}
                </li>

                <li>
                  Fundacja w ramach realizacji celu przetwarzania może
                  przekazywać dane osobowe podmiotom współpracującym z nią przy
                  realizacji działań edukacyjnych, animacyjnych i sieciujących
                  na terenie wrocławskiego osiedla Plac Grunwaldzki, w tym np.
                  podwykonawcom, urzędom dokonującym rozliczeń publicznoprawnych
                  pracowników i kontrahentów Fundacji, a w odniesieniu do danych
                  osobowych podawanych w związku z epidemią COVID – 19 – organom
                  nadzoru sanitarno – epidemiologicznego i porządku publicznego.
                  Podmioty te będą miały prawo do ich wykorzystania w pełnym
                  zakresie zgody udzielonej na ich wykorzystanie na rzecz
                  Fundacji.{" "}
                </li>

                <li>
                  Dane udostępnione przez Zgłaszających nie będą podlegały
                  profilowaniu.{" "}
                </li>

                <li>
                  Fundacja danych nie ma zamiaru przekazywać danych osobowych do
                  państwa trzeciego lub organizacji międzynarodowej.{" "}
                </li>

                <li>
                  Dane osobowe zgromadzone w celu zawarcia i realizacji umowy,
                  będą przechowywane przez Fundację w czasie jej realizacji oraz
                  przez okres 10 lat czyli w czasie wymaganym przez organy
                  kontrolne Państwa w szczególności ZUS i US.{" "}
                </li>

                <li>
                  Fundacja dokłada wszelkich starań, aby zapewnić wszelkie
                  środki fizycznej, technicznej i organizacyjnej ochrony danych
                  osobowych przed ich przypadkowym czy umyślnym zniszczeniem,
                  przypadkową utratą, zmianą, nieuprawnionym ujawnieniem,
                  wykorzystaniem czy dostępem, zgodnie ze wszystkimi
                  obowiązującymi przepisami.
                </li>
              </ul>
            </div>
            <div css={buttonContainerStyle}>
            <button type="submit">Akceptuję i wysyłam zgłoszenie</button>
            </div>
          </form>
        </article>
      </TextWrapper>
    </ArticleComponent>
  );
};

export default Formularz;
