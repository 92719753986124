import * as React from "react";
import { css } from "@emotion/core";

import Layout from "../components/Layout";
import Seo from "../components/Seo";

const containerStyles = css`
  h2 {
    font-size: 1.1em;
  }
`

const ArticleComponent = ({ title, children, preview = false }) => {
  return (
    <Layout preview={preview}>
      <Seo title={title} />
      <div css={containerStyles}>{children}</div>
    </Layout>
  );
};

export default ArticleComponent;
